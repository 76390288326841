body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: #000000;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

h1 {
  font-size: 3rem;
}

h6 {
  font-size: 20px;
}

h5 {
  line-height: 30px;
}

.ntgr-home {
  padding-top: 12%;
  padding-bottom: 12%;
  background-image: url("../src/Assets/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ntgr-about {
  padding-bottom: 0%;
}

.ntgr-otherpages {
  padding-bottom: 3%;
}

.ntgr-home h3 {
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: center;
}

.ntgr-home p {
  text-align: center;
}

p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 23px;
}

.ntgr-logo {
  width: 65%;
}

.nav-link {
  color: #000000;
  font-weight: 500;
}

.nav-link:hover {
  color: #ed1c24;
}

.dropdown-toggle::after {
  color: #ed1c24;
}

.ntgr-header {
  background-color: #ed1c24;
  padding-top: 1%;
  padding-bottom: 1%;
}

.ntgr-text-end {
  color: #ffffff;
  font-weight: 600;
  text-align: right;
  margin-bottom: 0%;

}

.ntgr-text-start {
  color: #ffffff;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0%;
}

.ntgr-dropdown-menu {
  border-radius: 0%;
  border-color: #ffffff;
  background-color: #ffffff;
}

.ntgr-dropdown-menu .dropdown-item {
  border-bottom: 1px solid #e1e1e1;
  padding-top: 5%;
  padding-bottom: 5%;
}

.dropdown-item:hover {
  border-bottom: 1px solid #ffffff;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #ed1c24;
  color: #ffffff;
}

.ntgr-transparent {
  position: absolute;
  width: 40%;
  height: 100%;
  right: 11%;
  background: rgba(0, 0, 0, 0.8);
}

.ntgr-slider-h2 {
  color: #ffffff;
  margin-top: 40%;
}

.ntgr-slider-p {
  color: #ffffff;
  line-height: 27px;
}

.ntgr-slider-button {
  margin-top: 5%;
  border: 1px solid #ffffff;
  background: transparent;
  border-radius: 0%;
}

.ntgr-slider-button:hover {

  border: 1px solid #ed1c24;
  background: #ed1c24;
  border-radius: 0%;
}

.ntgr-btn-services {
  margin-top: 5%;
  border: 1px solid #ffffff;
  background: transparent;
  border-radius: 0%;
}

.ntgr-btn-services:hover {
  border: 1px solid #ed1c24;
  background: #ed1c24;
  border-radius: 0%;
}



.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {

  background-color: transparent;
  border-color: #ed1c24;
}

.navbar-toggler:focus {

  box-shadow: none;
}

.ntgr-services-home {
  /* background-color: #e1e1e1; */
  background-color: #f9f9f9;
  padding-top: 8%;
}

.ntgr-clients-home {
  padding-top: 10%;
  padding-bottom: 12%;
  
}

.ntgr-services-blocks {
  position: relative;

}

.ntgr-services-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
}

.ntgr-services-item {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0%;
  background: #ed1c24;
}

.ntgr-services-item h5 {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 9%;
  color: #ffffff;
}

.ntgr-services-overlay p {
  color: #ffffff;
  margin-top: 40%;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.width100 {
  width: 100%;
}

.ntgr-color {
  color: #ed1c24;
}

.accordion-button {
  color: #ed1c24;
}

.accordion-button:focus {
  border-color: #ed1c24;
  box-shadow: none;
  background: #ffffff;
}

.accordion-button:not(.collapsed) {
  color: #ed1c24;
}

.card-title {
  color: #ed1c24;
}

.ntgr-center-text {
  text-align: center;
}

.card {
  border: none;
}

.ngtr-center-team h5 {
  text-align: center;
}

.ntgr-contact-color strong {
  color: #ed1c24;
}

.ntgr-contact-btn {
  background: #ed1c24;
  border-radius: 0%;
  border-color: #ed1c24;
}

.ntgr-contact-btn:focus {
  background: #ed1c24;
  border-radius: 0%;
  border-color: #ed1c24;
}

.ntgr-contact-btn:hover {
  background: #ffffff;
  color: #ed1c24;
  border-color: #ed1c24;
}

.form-control {
  border-radius: 0%;
}

.form-control:focus {
  color: #000000;
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: none;
}


.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #ed1c24;
  border-color: #ed1c24;
  color: #ffffff;
}

.ntgr-footer {
  background-image: url("../src/Assets/bg.png");
  background-position: center;
  background-size: cover;
  background-size: cover;
  background-color: #262626;
  /* background-color: #192136; */
  padding-top: 6%;
}

.ntgr-services-block-p {
  line-height: 28px;
}

.ntgr-services1-bg {
  background-color: #192136;
}

.ntgr-services2-bg {
  background-color: #ed1c24;
}

.ntgr-footer a,
h5 {
  color: #ffffff;
}

.ntgr-footer p {
  color: #ffffff;
}

.ntgr-footer .link-dark {
  color: #ffffff !important;
}

.ntgr-footer-input {
  border-radius: 0%;
}

.ntgr-footer-button {
  background: #ed1c24;
  border-radius: 0%;
  border-color: #ed1c24;
}

.ntgr-news-button {
  background: #ed1c24;
  border-radius: 0%;
  border-color: #ed1c24;
}

.ntgr-news-button:hover {
  color: #ed1c24;
  background: transparent;
  border-color: #ed1c24;
}

.ntgr-footer-button:hover {
  background: transparent;
  border-color: #ed1c24;
}


.ntgr-left-arrow {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff0000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  position: absolute;
  left: 0%;
}

.ntgr-right-arrow {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff0000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  position: absolute;
  right: 0%;
}

.ntgr-asset-management {
  width: 50%;
}

.ntgr-bold {
  font-weight: 800;
  color: #ed1c24;
}

.ntgr-about-subheading {
  margin-top: 15%;
}

.ntgr-values-bg {
  background: #e1e1e1;
  padding-top: 5%;
  padding-bottom: 11%;
}

.ntgr-values-icons {
  font-size: 40px;
  color: #ed1c24;
}

.ntgr-why-icons {
  left: 0% !important;
  top: 18% !important;
  position: absolute;
  color: #ed1c24;
}

.ntgr-values-list {
  padding-top: 2%;
  padding-bottom: 2%;
  border-bottom: none;
}

.ntgr-pages-banner {
  position: relative;
}

.ntgr-header-icons {
  color: #ffffff !important;
  font-size: 27px;
  margin: 0;
}

.ntgr-remove-margin {
  margin-bottom: 0rem;
  position: absolute;
  top: 1%;
}

.ntgr-gray-about {
  background: #e1e1e1;
}

th {
  font-size: 15px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  width: 33%;
}

.wp-block-image {
  margin: auto;
  text-align: center !important;
}

.ntgr-news-section p {
  margin-bottom: 1%;
}

.ntgr-news-section strong {
  color: #ed1c24;
  font-weight: bold;
}

.ntgr-news-section a {
  color: #ed1c24;
}

.has-text-align-center {
  text-align: center;
}

.size-full img {
  width: 50% !important;
  height: auto !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #ed1c24;
}

.block-header-slider .carousel-control-prev-icon {
  background-color: rgba(0, 0, 0, 0.9);  
  border-radius: 50% !important;  
  width: 50px;         
  height: 50px;       
  background-size: 20px 20px; 
  background-position: center;
}

.block-header-slider .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.9);  
  border-radius: 50% !important;  
  width: 50px;         
  height: 50px;       
  background-size: 20px 20px; 
  background-position: center;
}

.block-home-text {
  font-size: 21px;
  line-height: 33px;
  margin-top: 20px;
}

.ntgr-home-clients-text {
  font-size: 18px;
  line-height: 30px;
}

.ntgr-services-icon {
  width: 20%;
}

.pt-6 {
  padding-top: 3.5rem !important;
}
.ntgr-services-prev-icon{
  background-color: rgba(0, 0, 0, 0.9);  
  border-radius: 50% !important;  
  width: 50px;         
  height: 50px;       
  background-size: 20px 20px; 
  background-position: center;
}
.ntgr-services-next-icon{
  background-color: rgba(0, 0, 0, 0.9); 
  border-radius: 50%;  
  width: 50px;         
  height: 50px;        
  background-size: 20px 20px;  
  background-position: center;
}
.ntgr-carousel-control{
  width: 0% !important;
}
@media all and (min-width:700px) and (max-width:780px) {
  .ntgr-transparent {
    width: 55%;
  }

  .ntgr-slider-h2 {
    margin-top: 9%;
  }

  .navbar-toggler {
    position: absolute;
    right: 0%;
  }
}

@media all and (max-width: 640px) {
  .ntgr-transparent {
    width: 55%;
  }

  .ntgr-footer a {
    font-size: 12px;
  }

  .ntgr-home h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  .ntgr-slider-h2 {
    margin-top: 27%;
    font-size: 22px;
  }

  .ntgr-slider-p {
    display: none;
  }

  .ntgr-slider-button {
    display: none;
  }

  .ntgr-logo-mobile {
    text-align: center;
  }

  .ntgr-dropdown-menu .dropdown-item {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .ntgr-logo {
    width: 40%;
  }

  .navbar-nav {
    padding-left: 40px;
  }

  .navbar-toggler {
    margin-left: 42%;
  }

  .size-full img {
    width: 100% !important;
  }
}